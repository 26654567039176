import React, {useContext} from 'react';
import styled from 'styled-components';
import {Button, message} from 'antd';
import {Link} from '@styled-icons/boxicons-regular';
import {Context} from '../AppContext';
const appConfig = require('../data.json');

function ShareLink(props) {
  const app = useContext(Context);
  const {item, pluginAction = null, extraStyle} = props;

  const copyShareLink = React.useCallback(
    async (item) => {
      if (pluginAction) {
        pluginAction(item);
        return;
      }

      try {
        let text = await app.actions.getReurl({
          id: item.id,
          image: encodeURIComponent(item.image),
          title: encodeURIComponent(item.title),
          outline: item.outline ? encodeURIComponent(item.outline) : '',
        });
        await navigator.clipboard.writeText(text);
        message.success('複製分享連結成功。');
      } catch (err) {
        console.warn('err');
        message.error('複製分享連結失敗，請稍後再試。');
      }
    },
    [app.actions, pluginAction],
  );

  return (
    <Wrapper extraStyle={extraStyle}>
      <span>Share:</span>
      <Button
        shape="circle"
        icon={<Link size={24} onClick={() => copyShareLink(item)} />}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-top: 1px solid ${appConfig.colors.border};
  padding: 30px 0px 65px 0px;

  & > span {
    margin-right: 10px;
    line-height: 1.75;
    color: ${appConfig.text};
    font-size: ${appConfig.fontSize.md}px;
    font-weight: 500;
  }

  ${(props) => props.extraStyle || ''}
`;

export default ShareLink;
