import React from 'react';
import {Download} from '@styled-icons/feather';
import {downloadFile} from '../../../Utils';
const config = require('../../../data.json');

export default function ProductFile({file, index}) {
  return file ? (
    <div
      style={{
        margin: '15px 0',
        display: 'flex',
        alignItems: 'center',
      }}>
      <div
        style={{
          backgroundColor: config.colors.main,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 40,
          height: 40,
          marginRight: 10,
        }}>
        <Download size={30} color="white" />
      </div>
      <div>
        <div>{`${index + 1}. ${file.name}`}</div>
        <div
          onClick={() => downloadFile(file.file)}
          style={{
            color: config.colors.main,
            cursor: 'pointer',
          }}>
          {file.file.split('/').slice(-1)}
        </div>
      </div>
    </div>
  ) : null;
}
